.navItemWarp {
  cursor: pointer;
  padding: 0 16px;
  z-index: 99999;
  // padding: 20px 0;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: relative;
  // background: var(--foreground-rgb);

  :global {
    .anticon {
      margin-left: 6px;
    }
  }

  > .navItemText {
    line-height: 0.8;
    font-weight: 500;
    color: var(--app-text-color);
    text-align: center;
    display: inline-block;
  }
  > .navItemTextBottom {
    height: 10px;
  }

  > .navItemMobileText {
    line-height: 1.2;
    color: var(--app-text-color);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  > .navDropdown {
    position: absolute;
    top: 22px;
    right: -150px;
    display: none;
    padding-top: 10px;
    .navDropdownContent {
      z-index: 99;
      background: var(--foreground-rgb);
      padding: 1rem 1rem;
      flex-direction: column;
      box-shadow: 0px 2px 12px 0px #e3e6f3;
      width: fit-content;
      border-radius: 10px;
      font-size: 1rem;
    }

    &:hover {
      display: flex;
    }

    > .navItemWarp {
      margin: 0;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 5px;
      border-radius: 5px;

      &:hover {
        background-color: #f6f6f6;
      }
    }
  }

  &:hover {
    > .navItemText {
      color: var(--app-link-hover);
    }

    > .navDropdown {
      display: flex;
    }
  }
}
.dropMore {
  margin-left: 5px;
}
@media screen and (max-width: 900px) {
  .navItemWarp {
    padding: 0 6px;
    justify-content: flex-start;
  }
}

// @media (max-width: 950px) and (min-width: 900px) {
//   .navItemWarp {
//     width: 100%;
//     // box-sizing: border-box;
//     justify-content: flex-start;
//   }
// }

@media (max-width: 980px) and (min-width: 900px) {
  .navItemWarp {
    padding: 0 6px;
    > .navItemText {
    }
  }
}

@media (max-width: 1280px) and (min-width: 980px) {
  .navItemWarp {
    padding: 0 6px;
    > .navItemText {
      font-size: 1rem;
    }
  }
}

@media screen and (min-width: 1280px) {
  .navItemWarp {
    > .navItemText {
      font-size: 1rem;
    }
  }
}

