.navHamburgerWarp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  position: relative;
  width: 23px;
  height: 35px;
  :global {
    .next-antd-drawer-mask {
      display: none !important;
    }
    .next-antd-drawer {
      display: none !important;
    }
  }

  .hamburgerLine {
    display: block;
    width: 23px;
    height: 2px;
    margin-bottom: 3px;
    position: relative;
    background: var(--app-text-color);
    border-radius: 1px;
    transition: all 0.3s ease-in-out;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.showClose {
    .hamburgerLine {
      position: absolute;

      &:nth-child(1) {
        transform: rotate(45deg) translate(1px, 1px);
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}

.leftMenuDrawerWarp {
  :global {
    .ant-drawer-content {
      background: var(--foreground-rgb);

      .ant-drawer-header {
        border-bottom: none;
        padding: 120px 2rem 30px;

        .ant-drawer-title {
          text-align: center;
          font-size: 22px;
          color: #141414;
        }

        .ant-drawer-close {
          margin-inline-end: 0;
        }

        .anticon-close {
          font-size: 22px;
          color: #141414;
        }
      }

      .ant-drawer-body {
        padding: 0;

        :global {
          .mobile-left-menu-item {
            flex: 1;
            padding: 0;
            margin-bottom: 30px;
            width: 60%;

            > span {
              width: 100%;
              font-size: 15px;
            }

            &:last-child {
              position: relative;
              margin-top: 30px;
              &:before {
                content: '';
                position: absolute;
                border-top: 1px solid var(--app-text-color);
                transform: scaleY(0.5);
                left: 0;
                top: -30px;
                right: 0;
              }
            }
          }
        }
      }
    }
  }

  .mobileNavMenuWarp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // overflow: auto;
    font-size: 18px;
    color: #000000;

    .linkTitle {
      display: block;
      width: 100%;
      // box-sizing: border-box;
      padding: 0 15px 20px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .footerCon {
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #f3f3f3;
    width: 100%;
    padding: 24px;
    .mediaSection {
      margin-left: 10px;
      display: block;
      > .link {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;

        color: #a1a3b1;
        transition: all 0.3s ease-in;
        margin: 0 10px;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: var(--app-primary-color);
        }

        img {
          width: 32px;
          height: 32px;
        }
      }

      &:last-child {
        > .link {
          display: block;
          margin: 10px 0 0 0;
          // padding-bottom: 10px;
          text-align: left;
        }
      }
    }
  }
}
// .avatar {
//   height: 2rem;
//   width: 2rem;
//   background-color: var(--app-primary-color);
//   text-align: center;
//   line-height: 2rem;
//   color: #000000;
//   border-radius: 2rem;
//   cursor: pointer;
// }

